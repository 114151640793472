
<template>
  <div id="user-edit-tab-info">
    <form>
      <!-- Avatar Row -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row">
            <img :src="loadedImage" class="mr-8 rounded h-24 w-24" />
            <!-- <vs-avatar :src="avatar" size="80px" class="mr-4" /> -->
            <div>
              <p class="text-lg font-medium mb-1 mt-4 sm:mt-0">{{ data.first_name  }} {{ data.last_name  }}</p>
              <input type="file" size="small" class="hidden" ref="updateAvatarInput" @change="fileUploadReader" accept="image/*">

              <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
              <vs-button size="small" class="mr-4 mb-4" @click="$refs.updateAvatarInput.click()">Update Image</vs-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="First Name" v-model="data.first_name" v-validate="'required|alpha_spaces'" placeholder="first name" name="first_name" />
          <span class="text-danger text-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="Last Name" v-model="data.last_name" v-validate="'required|alpha_spaces'" placeholder="last name" name="last_name" />
          <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" placeholder="email" :readonly="!!data.is_verified" label="Email" v-model="data.email" type="email" v-validate="'required|email'" name="email" />
          <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="Mobile" v-model="data.mobile" v-validate="{regex: '^\\+?([0-9]+)$' }" placeholder="mobile" name="mobile" />
            <span class="text-danger text-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
            <!-- Gender -->
          <div class="mt-4">
            <label class="text-sm">Gender</label>
            <div class="mt-2">
              <vs-radio v-model="data.gender" vs-value="male" class="mr-4">Male</vs-radio>
              <vs-radio v-model="data.gender" vs-value="female" class="mr-4">Female</vs-radio>
            </div>
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button type="filled" ref="loadableButton" id="update-user-loading" @click.prevent="updateUser" class="mt-2 block vs-con-loading__container">Update</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="patchUser">Reset</vs-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

export default {

  data() {
    return {
      loadedImage: "",
      newImage: null,
      data: {},
    }
  },
  computed: {
    appActiveUser(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser() {
        this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          formData.append('image', this.newImage )
          this.$vs.loading({ color: "#444", type: "sound"})
          this.$http.post(`/account/update`,formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO', response.data.data.user)
            }
         })
        }
      })
    },
    fileUploadReader(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.loadedImage = e.target.result
        }
        this.newImage = input.target.files[0]
        reader.readAsDataURL(input.target.files[0])
      }
    },
    patchUser(){
      let loadedUser = this.appActiveUser
      this.data = {
        first_name: loadedUser.first_name,
        last_name: loadedUser.last_name,
        email: loadedUser.email,
        mobile: loadedUser.mobile,
        gender: loadedUser.gender,
        is_verified: loadedUser.is_verified,
      }
      this.loadedImage = this.absoluteUrl(loadedUser.image)
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
