
<template>
  <div id="user-edit-tab-info">
    <form>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <div class="flex items-end">
            <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Personal Information</span>
          </div>
          <div>
            <div class="mt-4">
              <label class="text-sm">Birth Date</label>
              <flat-pickr v-model="data.dob" :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" placeholder="date of birth" name="dob" />
              <span class="text-danger text-sm"  v-show="errors.has('dob')">{{ errors.first('dob') }}</span>
            </div>
            <div class="mt-4">
              <label class="text-sm">Languages</label>
              <v-select v-model="data.languages" label="name" :reduce="item => item.id" placeholder="--select--" multiple :closeOnSelect="false" :options="languages" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>
            <div class="mt-4">
              <label class="text-sm">Skills</label>
              <v-select v-model="data.skills" multiple label="name" :reduce="item => item.id"  placeholder="--select--" :closeOnSelect="false" :options="skills" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
            </div>
            <div class="mt-4">
              <label class="text-sm">Country</label>
              <v-select v-model="data.country_id" label="name" v-validate="{ required: true}" :reduce="item => item.id"  name="country" placeholder="--select--" :closeOnSelect="true" :options="countries" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
              <span class="text-danger text-sm"  v-show="errors.has('country')">{{ errors.first('country') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="Share2Icon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Social</span>
          </div>
          <div>
            <vs-input
              class="w-full mt-4"
              v-model="data.twitter"
              icon-pack="feather"
              icon="icon-twitter"
              label="Twitter"
              icon-no-border
              placeholder="twitter"
              v-validate="'url:require_protocol'"
              name="twitter" />
              <span class="text-danger text-sm"  v-show="errors.has('twitter')">{{ errors.first('twitter') }}</span>

            <vs-input
              class="w-full mt-4"
              v-model="data.facebook"
              icon-pack="feather"
              icon="icon-facebook"
              label="Facebook"
              icon-no-border
              placeholder="facebook"
              v-validate="'url:require_protocol'"
              name="facebook" />
              <span class="text-danger text-sm"  v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>

            <vs-input
              class="w-full mt-4"
              v-model="data.instagram"
              icon-pack="feather"
              icon="icon-instagram"
              label="Instagram"
              icon-no-border
              placeholder="instagram"
              v-validate="'url:require_protocol'"
              name="instagram" />
              <span class="text-danger text-sm"  v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>

            <vs-input
              class="w-full mt-4 "
              v-model="data.github"
              icon-pack="feather"
              icon="icon-github"
              label="GitHub"
              icon-no-border
              placeholder="github"
              v-validate="'url:require_protocol'"
              name="github" />
              <span class="text-danger text-sm"  v-show="errors.has('github')">{{ errors.first('github') }}</span>

          </div>
        </div>

        <div class="vx-col w-full">
          <div class="mt-6">
            <vs-textarea label="About" rows="3" name="about" v-validate="'max:10000'" v-model="data.about" placeholder="Enter bio..." />
            <span class="text-danger text-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-2 flex flex-wrap items-center justify-end">
            <vs-button type="filled" @click.prevent="updateUser()" class="mt-2 block">Update</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="patchUser()">Reset</vs-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr
  },
  data() {
    return {
      data:{},
    }
  },
  computed: {
    appActiveUser(){
      return this.$store.state.AppActiveUser
    },
    countries(){
      return this.$store.state.countries;
    },
    languages(){
      return this.$store.state.languages;
    },
    skills(){
      return this.$store.state.skills;
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$vs.loading({ color: "#444", type: "sound"})
          this.$http.post(`/account/update-info`, formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO',response.data.data.user)
            }
          })
        }
      })
    },
    patchUser(){
      let loadedUser = this.appActiveUser
      this.data = {
        about: loadedUser.about,
        skills: loadedUser.skills.map(s => s.id),
        dob: loadedUser.dob ? new Date(loadedUser.dob) : null,
        gender: loadedUser.gender,
        country_id: loadedUser.country_id,
        languages: loadedUser.languages.map(l => l.id),
        twitter: loadedUser.twitter,
        facebook: loadedUser.facebook,
        instagram: loadedUser.instagram,
        github: loadedUser.github,
      }
    }
  },
  created(){
    this.patchUser()
  }
}
</script>
